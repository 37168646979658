import React from "react";
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import Bbreadcrumbs from "../components/bbreadcrumbs.js";
/*Componentes*/
import SectionServices from "../components/sectionService/sectionServices.js";

import * as actionsServices from '../actions/servicesActions'

class Services extends React.Component {
  constructor(props) {
    super(props);

    this.loadServices();
  }
  loadServices() {
    this.props.dispatch(actionsServices.loadAll());
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>Brindamos soluciones de calidad, valores, respeto y profesionalismo</title>
          <meta name="description"
            content="Contamos con mas de 10 años de experiencia en el rubro logístico, brindando soluciones de calidad, valores, respeto y profesionalismo ante la sociedad y el medio ambiente." />
        </Helmet>
        <main id="main">
          
        <Bbreadcrumbs />
          <SectionServices services={this.props.services}/>
        </main>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    services: state.services
  }
}
export default connect(mapStateToProps)(Services);