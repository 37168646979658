import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import persistState from 'redux-localstorage'
import reducers from '../reducers';
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk';

import { connectRouter } from 'connected-react-router'
export const history = createBrowserHistory()

const enhancer = compose()(
    persistState('user')
);

const rootReducer = (history) => combineReducers({
    ...reducers,
    router: connectRouter(history),
    // rest of your reducers
})
export default function configureStore(middleware) {
    return createStore(
        rootReducer(history),
        compose(
            persistState(['user','business']),
            applyMiddleware(middleware, thunk)
        )
    );
}
