import costumers from './costumersReducer';
import portfolios from './portfoliosReducer';
import user from './userReducer';
import coverPages from './coverPagesReducer';
import business from './businessReducer';
import services from './servicesReducer';
import links from './linksReducer';

export default {
    costumers, user, portfolios, coverPages
    , business, services, links
}