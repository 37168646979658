import React from "react";

export default class ServiceContainer extends React.Component {
  
    render() {
        return (
          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0 marg-bott-3">
              <div className="icon-box" data-aos="fade-up">
                <div className="icon"><i className={this.props.service.icon}></i></div>
                <h4 className="title"><a href={this.props.service.url}>{this.props.service.title}</a></h4>
        <p className="description">{this.props.service.resumen}</p>
              </div>
            </div>
        );
    }
}