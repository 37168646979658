import React from "react";

export default class CostumerContainer extends React.Component {
    render() {
        return (
            <div className="col-lg-4 col-md-6">
                <div className="member" data-aos="fade-up">
                    <div className="pic">
                        <img src={this.props.costumer.img} className="img-fluid" alt="" />
                    </div>
                    <div className="member-info">
                        <h4>{this.props.costumer.title}</h4>
                    </div>
                </div>
            </div>
        );
    }
}