import config from '../config/secrets'

function getCostumers() {
    return fetch(config.url + "/request/costumer.js").then(data => {
        return data.json();
    }).catch(console.log);
}
export { getCostumers};
export default {
    costumers: [
        {
            img: 'assets/img/costumers/SIDERPERU_2.webp',
            title: 'SIDERURGICA DEL PERU',
        },
        {
            img: 'assets/img/costumers/COMACSA_2.webp',
            title: 'COMPAÑÍA MINERA AGREGADOS CALCAREOS',
        },
        {
            img: '',
            title: 'SICSA COMERCIAL',
        },
        {
            img: 'assets/img/costumers/YARAPERU_2.webp',
            title: 'YARA PERU'
        },
        {
            img: 'assets/img/costumers/ACEROSCOMERCIALES_2.webp',
            title: 'ACEROS COMERCIALES',
        },
        {
            img: 'assets/img/costumers/MISTI_2.webp',
            title: 'CORPORACION MISTI',
        },
        {
            img: 'assets/img/costumers/ferralia_2.webp',
            title: 'FERRALIA',
        },
        {
            img: 'assets/img/costumers/unacem_2.webp',
            title: 'UNACEM',
        },
        {
            img: 'assets/img/costumers/acerosarequipa.webp',
            title: 'ACEROS AREQUIPA',
        },
        {
            img: 'assets/img/costumers/ersigroup.webp',
            title: 'ERSIGROUP',
        },
        {
            img: 'assets/img/costumers/depconst.webp',
            title: 'DEPCONST',
        },
        {
            img: 'assets/img/costumers/jcbestructuras.webp',
            title: 'JCB ESTRUCTURAS',
        },
        {
            img: 'assets/img/costumers/ensol.webp',
            title: 'ENSOL',
        },
        {
            img: 'assets/img/costumers/carboinsac.webp',
            title: 'CARBOIN',
        },
    ]
}