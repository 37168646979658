import React from "react";
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SlideContainer from "components/hero/slideContainer.js";

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);


class Hero extends React.Component {
  slideContainerList() {
    return this.props.coverPages.map((slide, index) => {
      return (<SwiperSlide key={index} virtualIndex={index}>
        <SlideContainer slide={slide} />
      </SwiperSlide>
      )
    });

  };
  render() {
    return (
      <section id="hero">
        <a href="#counts" className="btn-get-started scrollto"><i className="bx bx-chevrons-down"></i></a>

        <div className="header-container">

          <div className="Headers-slider swiper-container" data-aos="fade-up" data-aos-delay="100">
            {this.props.coverPages.length > 0 && (
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                navigation
                loop={true}
                speed={3000}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false
                }}

                scrollbar={{ draggable: true }}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
              >
                {this.slideContainerList()}
              </Swiper>)}
          </div>

        </div>
      </section>
    );
  }
}

export default Hero;
