import React from "react";
import LinkContainer from "components/header/linkContainer.js";
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'

class Header extends React.Component {
  linkContainerList() {
    return this.props.links.map((link, index) => {
      if (link.links !== undefined) {
        return (
          <NavDropdown key={index} title={link.title} id="basic-nav-dropdown">
            {
              link.links.map((subLink, index) => {
                return (<NavDropdown.Item key={index} href={subLink.hrefLink}>{subLink.title}</NavDropdown.Item>)
              })}
          </NavDropdown>)
      } else {
        return (<LinkContainer key={index} link={link} />);
      }


    });

  };
  render() {
    return (
      <header id="header" >
        <div className="container d-flex align-items-center justify-content-between">
          <Navbar id="navbar" expand="lg" className="container-fluid">
            <Navbar.Brand href="index" className="logo">
              <img src={`${process.env.PUBLIC_URL}/assets/img/logo.webp`} alt="" className="img-fluid" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="ml-auto">
              <Nav className="ml-auto">
                {this.linkContainerList()}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </header>
    );
  }
}
export default Header;