import * as requests from '../requests/business';

export function loadBusiness(business) {
    return { type: 'LOAD_BUSINESS', business };
}

export function loadAll(count) {
    return (dispatch, getState) => {
        requests.getBusiness().then(result => {
                dispatch(loadBusiness(result));
        })
    }
}