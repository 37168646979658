import * as requests from '../requests/portfolio';

export function loadPortfolios(portfolios) {
    return { type: 'LOAD_PORTFOLIOS', portfolios };
}

export function loadAll(count) {
    return (dispatch, getState) => {
        requests.getPortfolios().then(result => {
            if (count > 0) {
                const resultFil = result.slice(0, count);
                dispatch(loadPortfolios(resultFil));
            } else {
                dispatch(loadPortfolios(result));
            }
        })
    }
}