import * as requests from '../requests/coverPage';

export function loadCoverPages(coverPages) {
    return { type: 'LOAD_COVERPAGES', coverPages };
}

export function loadAll(){
    return (dispatch, getState) => {
        requests.getCoverPages().then(result => {
            dispatch(loadCoverPages(result));
        })
    }
}