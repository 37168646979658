import * as requests from '../requests/service';

export function loadServices(services) {
    return { type: 'LOAD_SERVICES', services };
}

export function loadAll() {
    return (dispatch, getState) => {
        requests.getServices().then(result => {
            dispatch(loadServices(result));
        })
    }
}