import React from "react";
import ServiceContainer from "components/sectionService/serviceContainer.js";

export default class SectionServices extends React.Component {
  serviceContainerList() {
    return this.props.services.map((service, index) => {
      return (
        <ServiceContainer key={index} service={service} />
      )
    });
  };
  render() {
    return (
      <section id="services" className="services">
        <div className="container">

          <div className="section-title" data-aos="fade-in" data-aos-delay="100">
            <h2>Servicios</h2>
          </div>

          <div className="row">
            {this.serviceContainerList()}

          </div>

        </div>
      </section>
    );
  }
}