import config from '../config/secrets'

function getLinks() {
    return fetch(config.url + "/request/link.js").then(data => {
        return data.json();
    }).catch(console.log);
}
export { getLinks};
export default {
    links: [
        {
            title: 'Inicio',
            relationLink: 'index',
            hrefLink: '/index'
        },
        {
            title: 'Nosotros',
            relationLink: '#op',
            hrefLink: '/about'
        },
        {
            title: 'Servicios',
            relationLink: 'services',
            hrefLink: '/services',
            links:[{
                title: 'Transporte de carga - Plataforma',
                hrefLink: '/services',
            },
            {
                title: 'Carga sobredimencionada',
                hrefLink: '/services',
            },
            {
                title: 'Encapsulados',
                hrefLink: '/services',
            },
            {
                title: 'Cisternas',
                hrefLink: '/services',
            }
            ]
        },
        {
            title: 'Portafolio',
            relationLink: 'portfolio',
            hrefLink: '/portfolio'
        },
        {
            title: 'Clientes',
            relationLink: 'costumers',
            hrefLink: '/costumers'
        },
        {
            title: 'Contacto',
            relationLink: 'contact',
            hrefLink: '/contact'
        },
    ]
}