import React from "react";

export default class ButtonWhatsapp extends React.Component {
  render() {
    return (
      <a href={this.props.linkWahtsap} className="float" target="_blank" rel="noreferrer">
        <i className="bx bxl-whatsapp whatsapp-float"></i>
      </a>
    );
  }
}