import React from "react";
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import Glightbox from 'glightbox';

/*Componentes*/
import SectionPortfolio from "../components/sectionPortfolio/sectionPortfolio.js";
import Bbreadcrumbs from "../components/bbreadcrumbs.js";

import * as actions from '../actions/portfoliosActions'


class Portfolio extends React.Component {
  constructor(props) {
    super(props);

    this.loadPorfolios();
  };
  componentDidUpdate() {
    const portfolioLightbox = Glightbox({
      selector: '.portfolio-lightbox'
    });
  };
  loadPorfolios() {
    this.props.dispatch(actions.loadAll(0));
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>Experiencia en diferentes sectores</title>
          <meta name="description"
            content="Contamos con mas de 10 años de experiencia en el rubro logístico, brindando soluciones de calidad, valores, respeto y profesionalismo ante la sociedad y el medio ambiente." />
        </Helmet>
        <main id="main">
          <Bbreadcrumbs />
          <SectionPortfolio btnMas={false} portfolios={this.props.portfolios} />
        </main>
      </div>
    );
  }
}
function mapStateToProps(state, ownProps) {
  return {
    portfolios: state.portfolios
  }
}
export default connect(mapStateToProps)(Portfolio);