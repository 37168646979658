import config from '../config/secrets'

function getCoverPages() {
    return fetch(config.url + "/request/coverPage.js").then(data => {
        return data.json();
    }).catch(console.log);
}
export { getCoverPages};
export default {
    slides: [
        {
            img: 'assets/img/portada.webp',
            title: 'TDA Logística S.A.C.',
            subTitle: 'Servicio logístico',
            description: 'Somos una empresa con más de 10 años de Experiencia en el sector de Transporte de Carga a Nivel de Nacional e Internacional, garantizando el mejor de los servicios.'
        },
        {
            img: 'assets/img/transportecargaPlataforma.webp',
            title: 'TDA Logística S.A.C.',
            subTitle: 'Transporte de carga - Plataforma',
            description: 'Somos una empresa con más de 10 años de Experiencia en el sector de Transporte de Carga a Nivel de Nacional e Internacional, garantizando el mejor de los servicios.'
        },
        {
            img: 'assets/img/cargaSobredimencionada.webp',
            title: 'TDA Logística S.A.C.',
            subTitle: 'Carga sobredimensionada',
            description: 'Transporte de carga sobredimensionada para equipos y maquinaria pesada. Apoyados por escoltas de experiencia.'
        },
        {
            img: 'assets/img/encapsulado_negro.webp',
            title: 'TDA Logística S.A.C.',
            subTitle: 'Encapsulados',
            description: 'Encapsulados para el transporte de concentrados de mineral y derivados.'
        },
        {
            img: 'assets/img/cisterna_negro.webp',
            title: 'TDA Logística S.A.C.',
            subTitle: 'Cisterna',
            description: 'Contamos con remolques especializados en el translado de productos liquidos, cumpliendo con las medidas y reguzaciones necesarias por la seguridad del producto y el medio ambiente.'
        },
    ]
}