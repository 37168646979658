import React from "react";

export default class SlideContainer extends React.Component {
  render() {
		return (
      <div>
      <img
        src={this.props.slide.img}
        alt=""
      />
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-7 mb-80">
            <div className="secundario-text">
              <h2>
                <span>{this.props.slide.title}</span>
              </h2>
              <h1 className="cd-headline clip">
                <span>{this.props.slide.subTitle}</span>
              </h1>
              <p className="header-desc">
                {this.props.slide.description}
            </p>
            </div>
          </div>
        </div>
      </div>
      </div>
		);
	}
}