import * as requests from '../requests/costumer';

export function loadCostumers(costumers) {
    return { type: 'LOAD_COSTUMERS', costumers };
}

export function loadAll(){
    return (dispatch, getState) => {
        requests.getCostumers().then(result => {
            dispatch(loadCostumers(result));
        })
    }
}