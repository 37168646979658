import React from "react";

export default class counts extends React.Component {
  render() {
		return (
            <section id="counts" className="counts  section-bg">
            <div className="container">
      
              <div className="row no-gutters">
      
                <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up">
                  <div className="count-box">
                    <i className="bi bi-geo-alt-fill"></i>
      
                    <span>+250</span>
                    <p><strong>Destinos</strong></p>
                  </div>
                </div>
      
                <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up">
                  <div className="count-box">
                    <i className="bi bi-journal-richtext"></i>
                    <span>+1Millon</span>
                    <p><strong>Envíos Realizados</strong></p>
                  </div>
                </div>
      
                <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up">
                  <div className="count-box">
                    <i className="bi bi-box-seam"></i>
                    <span>+1Millon</span>
                    <p><strong>Toneladas transportadas</strong></p>
                  </div>
                </div>
      
                <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up">
                  <div className="count-box">
                    <i className="bi bi-truck"></i>
                    <span>+500000</span>
                    <p><strong>Viajes Realizados</strong></p>
                  </div>
                </div>
      
              </div>
      
            </div>
          </section>
		);
	}
}