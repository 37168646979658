import React from "react";
import PortfolioContainer from "./portfolioContainer";

export default class SectionPorfolio extends React.Component {


  portfolioContainerList() {
    return this.props.portfolios.map((portfolio, index) => {
      return (
        <PortfolioContainer key={index} portfolio={portfolio} />
      )
    });
  };
  buttonMasPortfolio() {
    if (this.props.btnMas === true)
      return (<div className="row" data-aos="fade-up">
        <div className="content col-xl-8 d-flex align-items-stretch aos-init aos-animate">
          <a href="portfolio" className="portfolio-btn">Ver mas <i className="bx bx-chevron-right"></i></a>
        </div>
      </div>)
  }
  render() {
    return (
      <section id="portfolio" className="portfolio">
        <div className="container">

          <div className="section-title" data-aos="fade-in" data-aos-delay="100">
            <h2>Portafolio</h2>
          </div>

          <div className="row portfolio-container" data-aos="fade-up">
            {this.props.portfolios && [
              this.portfolioContainerList()
            ]
            }
          </div>
          {this.buttonMasPortfolio()}

        </div>
      </section>
    );
  }
}