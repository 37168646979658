import React from "react";

export default class Footer extends React.Component {
    render() {
        return (
            <footer id="footer">

                <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong><span>danielsl</span></strong>. All Rights Reserved
              </div>
                    <div className="credits">
                        Designed by <a href="https://danielsl.com/">danielsl</a>
                    </div>
                </div>
            </footer>
        );
    }
}