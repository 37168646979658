import React from "react";
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import Glightbox from 'glightbox';

/*Componentes*/
import Hero from "../components/hero/hero.js";
import Counts from "../components/counts.js";
import SectionAbout from "../components/sectionAbout.js";
import Services from "../components/sectionService/sectionServices.js";
import SectionPortfolio from "../components/sectionPortfolio/sectionPortfolio.js";
import SectionCostumers from "../components/sectionCostumer/sectionCostumers.js";

import * as actionsCostumers from '../actions/costumersActions'
import * as actionsPortfolio from '../actions/portfoliosActions'
import * as actionsCoverPage from '../actions/coverPagesActions'
import * as actionsServices from '../actions/servicesActions'

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.loadCoverPage();
    this.loadCostumers();
    this.loadPorfolios();
    this.loadServices();
  }
  loadServices() {
    this.props.dispatch(actionsServices.loadAll());
  }
  loadCoverPage() {
    this.props.dispatch(actionsCoverPage.loadAll());
  }
  loadCostumers() {
    this.props.dispatch(actionsCostumers.loadAll());
  }
  loadPorfolios() {
    this.props.dispatch(actionsPortfolio.loadAll(5));
  };
  componentDidUpdate() {
    const portfolioLightbox = Glightbox({
      selector: '.portfolio-lightbox'
    });
  };
  render() {
    return (
      <div>
        <Helmet>
          <title>TDA Logistica - Empresa peruana dedicada al transporte de carga pesada a nivel nacional e internacional
  </title>
          <meta name="description"
            content="Contamos con mas de 10 años de experiencia en el rubro logístico, brindando soluciones de calidad, valores, respeto y profesionalismo ante la sociedad y el medio ambiente." />
        </Helmet>
        <Hero coverPages={this.props.coverPages} />
        <main id="main">
          <Counts />
          <SectionAbout />
          <Services services={this.props.services} />
          <SectionPortfolio btnMas={true} portfolios={this.props.portfolios} />
          <SectionCostumers costumers={this.props.costumers} />
        </main>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    costumers: state.costumers,
    portfolios: state.portfolios,
    coverPages: state.coverPages,
    services: state.services
  }
}
export default connect(mapStateToProps)(Index);
