import React from "react";
import { Helmet } from "react-helmet";

import { connect } from 'react-redux';

/*Componentes*/
import SectionCostumers from "../components/sectionCostumer/sectionCostumers.js";
import Bbreadcrumbs from "../components/bbreadcrumbs.js";

import * as actions from '../actions/costumersActions'

class Costumers extends React.Component {

  constructor(props) {
    super(props);

    this.loadCostumers();
  }
  loadCostumers() {
    this.props.dispatch(actions.loadAll());
  }

  render() {    
    return (
      <div>
        <Helmet>
          <title>Nuestros clientes</title>
          <meta name="description"
            content="Contamos con mas de 10 años de experiencia en el rubro logístico, brindando soluciones de calidad, valores, respeto y profesionalismo ante la sociedad y el medio ambiente." />
        </Helmet>
        <main id="main">
          <Bbreadcrumbs />
          <SectionCostumers costumers={this.props.costumers} />
        </main>
      </div>
    );
  }
}
function mapStateToProps(state, ownProps) {
  return {
    costumers: state.costumers
  }
}
export default connect(mapStateToProps)(Costumers);