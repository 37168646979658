import React from "react";

export default class SectionAbout extends React.Component {
  render() {
		return (
            <section id="about" className="about">
            <div className="container">
            <div className="section-title">
            <h2>Nosotros</h2>
          </div>
              <div className="row no-gutters">
                <div className="content col-xl-5 d-flex align-items-stretch" data-aos="fade-up">
                  <div className="content">
                    <h3>TDA Logistica S.A.C.</h3>
                    <h4>¡Una empresa logística a nivel nacional e internacional!</h4>
                    <p>
                      Contamos con experiencia dedicada a brindar soluciones de calidad, valores, respeto y profesionalismo
                      ante la sociedad y el medio ambiente, buscando superar las expectativas del cliente. Creemos que parte
                      de la mejora continua se basa en la constante capacitación de los conductores y seguimiento de las
                      unidades.
                    </p>
                    <p>
                      Contamos con experiencia en el sector minero, comercio, construcción y mercancías en general con
                      seguimiento y monitoreo satelital.
                      Nos comprometemos y garantizamos generar beneficios para la sociedad y para su empresa basado en valores
                      como coherencia, transparencia, honestidad y lealtad.
                    </p>
                  </div>
                </div>
                <div className="col-xl-7 d-flex align-items-stretch">
                  <div className="icon-boxes d-flex flex-column justify-content-center">
                    <div className="row">
                      <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                        <i className="bx bx-user-check"></i>
                        <h4>Clientes</h4>
                        <p>Nos enfocamos en brindar un servicio logístico de calidad, adaptado a sus necesitados que le
                          permita dedicarse a sus actividades principales.</p>
                      </div>
                      <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                        <i className="bx bx-cube-alt"></i>
                        <h4>Experiencia en</h4>
                        <p>Sector minero, comercio, construcción, mercancías entre otros.</p>
                      </div>
                      <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                        <i className="bx bx-shield"></i>
                        <h4>Monitoreo</h4>
                        <p>Nuestros vehículos cuentan con seguimiento y monitoreo satelital, ademas de camionetas de resguardo
                          con lo se aseguramos el traslado de sus productos.</p>
                      </div>
                      <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                        <i className="bx bx-map-alt"></i>
                        <h4>Internacional</h4>
                        <p>Nuestra trayectoria y confianza con nuestros clientes nos a llevado por mas de 250 destinos
                          nacionales e internacionales.</p>
                        <p>Perú - Todos los departamentos</p>
                        <p>Bolivia - La Paz, Santa cruz</p>
                        <p>Chile - Antofagasta, Santiago de Chile, Arica, Puerto Magallanes</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
      
            </div>
          </section>
		);
	}
}