import React from "react";
import { Helmet } from "react-helmet";
import Bbreadcrumbs from "../components/bbreadcrumbs.js";

export default class Workwithus extends React.Component {
  render() {
    return (
      <div>
	<Helmet>
		<title>Trabaja con Nosotros</title>
		<meta name="description"
    			content="Contamos con mas de 10 años de experiencia en el rubro logístico, brindando soluciones de calidad, valores, respeto y profesionalismo ante la sociedad y el medio ambiente." />
	</Helmet>
	<main id="main">
  <Bbreadcrumbs />
		<section id="about"
       			className="about">
			<div className="container">
				<div className="section-title">
					<h2>Trabaja con nosotros</h2>
				</div>
				<div className="row no-gutters">
					<div className="content col-xl-12 d-flex align-items-stretch"
   						data-aos="fade-up">
						<div className="content">
							<h4>¡Trabaja con nosotros!</h4>
							<p>
								Tenemos la puerta abierta para nuevos profesionales, ¡únete y haz que las cosas buenas sigan sucediendo!
							</p>
							<p>
								Nos encontramos en una constante búsqueda de personas talentosas. Todos nuestros colaboradores se caracterizan por estar siempre dispuestos a asumir nuevos retos y por saber identificar las oportunidades desarrollo profesional en un negocio retador e inspirador, con un ambiente de trabajo agradable y cooperativo.
							</p>
							<p>
								Las oportunidades en nuestra empresa son constantes, así que si quieres mantenerte enterado de nuestras convocatorias, síguenos en <a title="Facebook" href="https://web.facebook.com/TDAlogistica" target="_bank" rel="noreferrer">facebook</a>.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</div>
    );
  }
}
