import React from 'react';
import ReactDOM from 'react-dom';
import Router from './router';
import configureStore, { history } from './store/configureStore';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import { routerMiddleware } from 'connected-react-router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'glightbox/dist/css/glightbox.min.css';

const middleware = routerMiddleware(history);

const store = configureStore(middleware);

ReactDOM.render(
  <Provider store={store} >
      <Router history={history} />
  </Provider>
  ,document.getElementById("root")
);
registerServiceWorker();