import React from "react";
function php_email_form_submit(thisForm, action, formData) {
  fetch(action, {
    method: 'POST',
    body: formData,
    headers: { 'X-Requested-With': 'XMLHttpRequest' }
  })
    .then(response => {
      if (response.ok) {
        return response.text()
      } else {
        throw new Error(`${response.status} ${response.statusText} ${response.url}`);
      }
    })
    .then(data => {
      thisForm.querySelector('.loading').classList.remove('d-block');
      if (data.trim() === 'OK') {
        thisForm.querySelector('.sent-message').classList.add('d-block');
        thisForm.reset();
      } else {
        throw new Error(data ? data : 'Form submission failed and no error message returned from: ' + action);
      }
    })
    .catch((error) => {
      displayError(thisForm, error);
    });
}

function displayError(thisForm, error) {
  thisForm.querySelector('.loading').classList.remove('d-block');
  thisForm.querySelector('.error-message').innerHTML = error;
  thisForm.querySelector('.error-message').classList.add('d-block');
}
export default class SectionContact extends React.Component {

  formsInter() {
    let forms = document.querySelectorAll('.php-email-form');

    forms.forEach(function (e) {
      e.addEventListener('submit', function (event) {
        event.preventDefault();

        let thisForm = this;

        let action = thisForm.getAttribute('action');
        let recaptcha = thisForm.getAttribute('data-recaptcha-site-key');
        if (!action) {
          displayError(thisForm, 'The form action property is not set!')
          return;
        }
        thisForm.querySelector('.loading').classList.add('d-block');
        thisForm.querySelector('.error-message').classList.remove('d-block');
        thisForm.querySelector('.sent-message').classList.remove('d-block');

        let formData = new FormData(thisForm);

        if (recaptcha) {
          if (typeof window.grecaptcha !== "undefined") {
            window.grecaptcha.ready(function () {
              try {
                window.grecaptcha.execute(recaptcha, { action: 'php_email_form_submit' })
                  .then(token => {
                    formData.set('recaptcha-response', token);
                    php_email_form_submit(thisForm, action, formData);
                  })
              } catch (error) {
                displayError(thisForm, error)
              }
            });
          } else {
            displayError(thisForm, 'The reCaptcha javascript API url is not loaded!')
          }
        } else {
          php_email_form_submit(thisForm, action, formData);
        }
      });
    });
  }

  guion(i) {
    if (i > 0) {
      return " - "
    };
  }
  componentDidMount() {
    this.formsInter();
  }
  render() {
    return (
      <section id="contact" className="contact section-bg">
        <div className="container" data-aos="fade-up">

          <div className="section-title">
            <h2>Contacto</h2>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="info-box mb-4">
                <i className="bx bx-map"></i>
                <h3>Encuentranos</h3>
                <p>{this.props.business.address}</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="info-box  mb-4">
                <i className="bx bx-envelope"></i>
                <h3>Envíanos un Email</h3>
                <p>{
                  this.props.business.emails && this.props.business.emails.map((email, index) => {
                    return (
                        <a key={index} href={`mailto:${email}`}>{this.guion(index)}{email}</a>
                    )
                  }
                  )
                }</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="info-box  mb-4">
                <i className="bx bx-phone-call"></i>
                <h3>Llamanos</h3>
                <p>{
                  this.props.business.phones && this.props.business.phones.map((phone, index) => {
                    return (
                        <a key={index} href={`tel:${phone}`}>{this.guion(index)}{phone}</a>
                    )
                  }
                  )
                }
                </p>
              </div>
            </div>

          </div>
          <div className="row">
            <div className="col-lg-6 ">
              <iframe className="mb-4 mb-lg-0"
                src={this.props.business.pointMaps}
                frameBorder="0" style={{ "border": "0", "width": "100%", "height": "384px" }} allowFullScreen loading="lazy"></iframe>
            </div>
            <div className="col-lg-6">
              <form action="forms/contact.php" method="post" role="form" className="php-email-form"
                data-recaptcha-site-key="6Lf3Ya8aAAAAAPQbexlwJbwSJQqXCNq-pBpbo2XM">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input type="text" name="name" className="form-control" id="name" placeholder="Nombre" required />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input type="email" className="form-control" name="email" id="email" placeholder="Email" required />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <input type="text" className="form-control" name="subject" id="subject" placeholder="Asunto" required />
                </div>
                <div className="form-group mt-3">
                  <textarea className="form-control" name="message" rows="5" placeholder="Mensaje" required></textarea>
                </div>
                <div className="my-3">
                  <div className="loading">Cargando</div>
                  <div className="error-message"></div>
                  <div className="sent-message">Tu mensaje ha sido enviado. ¡Gracias!</div>
                </div>
                <div className="text-center"><button type="submit">Enviar</button></div>
              </form>
            </div>

          </div>

        </div>
      </section>
    );
  }
}