import * as requests from '../requests/link';

export function loadLinks(links) {
    return { type: 'LOAD_LINKS', links };
}

export function loadAll() {
    return (dispatch, getState) => {
        requests.getLinks().then(result => {
            dispatch(loadLinks(result));
        })
    }
}