import React from "react";

export default class PortfolioContainer extends React.Component {
    render() {
        return (
          <div className="col-lg-4 col-md-6 portfolio-item filter-app">
          <div className="portfolio-wrap">
            <img src={this.props.portfolio.img} className="img-fluid" alt="" />
            <div className="portfolio-links">
              <a href={this.props.portfolio.img} data-gallery="portfolioGallery"
                className="portfolio-lightbox" title=""><i className="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
        );
    }
}