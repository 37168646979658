import React from "react";
import {  Nav } from 'react-bootstrap'
export default class LinkContainer extends React.Component {
    FirtsLink(link) {
        var isActive = window.location.pathname === this.props.link.hrefLink;
        var activeLink = isActive ? 'active' : '';
        if (link.links !== undefined) {
            if (link.links.length > 0) {
                return (<a href={link.hrefLink}><span>{link.title}</span> <i className="bi bi-chevron-down"></i></a>);
            }
        } else {
            return (<a className={`nav-link scrollto ${activeLink}`} href={link.hrefLink}>{link.title}</a>);
        }
    }
    SubLink(link, index) {
        return (<li key={index}><a href={link.hrefLink}>{link.title}</a></li>);
    }
    SubLinks(link) {
        if (link.links !== undefined) {
            return (<ul>
                {link.links.map((subLink, index) => {
                    return this.SubLink(subLink, index);
                })}
            </ul>);
        }
    }
    render() {
        return (
          <Nav.Link href={this.props.link.hrefLink}>{this.props.link.title}</Nav.Link>

        );
    }
}