import React from "react";
import CostumerContainer from "components/sectionCostumer/costumerContainer.js";

export default class SectionCostumers extends React.Component {

    costumerContainerList() {
        return this.props.costumers.map((costumer, index) => {
            return (
                <CostumerContainer key={index} costumer={costumer} />
            )
        });
    };
    render() {
        return (
            <section id="costumers" className="team">
                <div className="container">

                    <div className="section-title" data-aos="fade-in" data-aos-delay="100">
                        <h2>Clientes</h2>
                        <p>Principales clientes que confían en nosotros.</p>
                    </div>

                    <div className="row">
                        {this.costumerContainerList()}

                    </div>
                </div>
            </section>
        );
    }
}