import React from "react";
import { Helmet } from "react-helmet";

/*Componentes*/
import SectionAbout from "../components/sectionAbout.js";
import Bbreadcrumbs from "../components/bbreadcrumbs.js";

export default class About extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Nosotros llegamos a todo latinoamerica</title>
          <meta name="description"
            content="Contamos con mas de 10 años de experiencia en el rubro logístico, brindando soluciones de calidad, valores, respeto y profesionalismo ante la sociedad y el medio ambiente." />
        </Helmet>
        <main id="main">
          <Bbreadcrumbs />
          <SectionAbout />
        </main>
      </div>
    );
  }
}